<template>
  <div class="container inicio">
    <div class="row">
      <div class="col-12">
        <img
          src="@/assets/mainLogoDark.png"
          alt="PREP"
          class="d-block img-fluid mx-auto mb-4"
          v-if="esOscuro"
        >
        <img src="@/assets/mainLogo.png" alt="PREP" class="d-block img-fluid mx-auto mb-4" v-else>
        <h3 class="mb-5">
          Elecciones Estatales de <br /> <span class="primario">Baja California Sur</span> 2021
        </h3>

        <div class="mb-5">
          <p>
            Los resultados presentados son preliminares,
            tienen un carácter informativo y no son definitivos,
            por tanto carecen de efectos jurídicos.
          </p>

          <p>
            El miércoles 9 de junio del 2021 darán inicio los Cómputos Distritales,
            los cuales determinan los resultados electorales en la Entidad.
          </p>
        </div>

        <router-link to="/G/ENT/VPC" class="btn btn-primary btn-home mb-5">
          Consulta Resultados Preliminares
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  computed: {
    esOscuro() {
      return this.$store.state.esOscuro;
    },
  },
  metaInfo: {
    title: 'IEEBCS - PREP 2021',
  },
};
</script>

<style lang="scss" scoped>
.inicio {
  max-width: 720px;
}
.btn-home {
  text-align: center;
  width: 100%;
  max-width: 358px;
  padding: 16px;
  display: block;
  margin: 0 auto;
  background-color: #D1308A;
  border-radius: 8px;
  border-color: #D1308A;
  font-weight: 500;

  &:active {
    background-color: #D1308A !important;
    border-color: initial !important;
  }
  &:hover {
    background-color: darken(#D1308A, 10%) !important;
  }
  &:focus {
    box-shadow: none !important;
  }
}

.col-12 {
  margin-top: 3rem;

  h3 {
    text-align: center;
    font-weight: 700;
  }

  p {
    font-weight: 400;
  }

  @media(min-width: 992px) {
    margin-top: 7rem;
  }
}

img {
  max-width: 300px;
}
</style>
